import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchMostReadArticles } from '../../store/mostReadArticles'

import SidebarArticleCard from './SidebarArticleCard'
import { Articles } from 'ion-article-cmp'

const MostReadArticles = (props) => {
  return (
    <>
      <h2>Most Popular</h2>
      <Articles
        section={props.section || 'news'} page0Limit={props.pageSize + 1} pageSize={props.pageSize + 1} fetchArticles={props.fetchMostReadArticles}
        noImage={props.noImage} notFound={() => (<div>Oops!</div>)} noDiv fetchMoreArticles={() => { }} articles={props.articles}
        component={({ articles }) => {
          return articles
            .filter(article => article.contentKey !== props.contentKey)
            .slice(0, props.pageSize)
            .map((article, index) => (
              <div key={'mr-' + article.uuid} className={props.className}>
                <SidebarArticleCard article={article} />
              </div>
            ))
        }}
      />
    </>
  )
}

MostReadArticles.propTypes = {
  articles: PropTypes.object.isRequired,
  fetchMostReadArticles: PropTypes.func.isRequired
}

MostReadArticles.defaultProps = {
  pageSize: 3
}

const mapStateToProps = (state) => ({ articles: state.mostReadArticles })
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchMostReadArticles }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MostReadArticles)
