import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Dimmer, Loader, Segment } from 'semantic-ui-react'
import { PulseLoader } from 'react-spinners'
import 'semantic-ui-css/components/dimmer.min.css'
import 'semantic-ui-css/components/loader.min.css'
import 'semantic-ui-css/components/segment.min.css'
import qs from 'qs'
import Helmet from 'react-helmet'

import { searchArticles, searchArticlesMore } from '../../store/search'
import IonArticle from 'ion-article'
import noImage from '../static/no-image.png'
import NoResults from '../static/icons/no-search-results-v2.svg'
import Sidebar from '../components/Sidebar'
import ArticleCard from '../components/ArticleCard'
import { Search } from 'ion-icon'
import MagazineCover from '../components/MagazineCover'
import { Mobile, DesktopTablet } from 'ion-media'

export class SearchPage extends Component {
  constructor () {
    super()
    this.state = {
      isHidden: false
    }
    this.page = 0
    this.index = 0
    this.pageSize = 15
    this.handleViewMore = this.handleViewMore.bind(this)
  }

  componentDidUpdate (prevProps) {
    // Re-fetch articles when the section changes
    if (prevProps.query !== this.props.query) {
      this.props.searchArticles(this.props.query, this.index, this.pageSize)
    }
  }

  UNSAFE_componentWillMount () {
    // Fetch the first time this component is mounted
    const data = this.props.articles
    const lastFetch = new Date(this.props.lastFetch || 0)
    const now = new Date()
    this.params = !this.props.query ? qs.parse(this.props.location.search, { ignoreQueryPrefix: true }) : { q: this.props.query }
    this.page = this.params.page || 1
    if (!data || now - lastFetch > 1000) {
      this.props.searchArticles(this.params.q, this.index, this.pageSize)
    }
  }

  componentDidMount () {
    // Display the server-side view more button
    this.setState({
      isHidden: !this.state.isHidden
    })
    if (typeof window !== 'undefined' && window.adngin) {
      window.adngin.queue.push(function () {
        // trigger a new auction
        window.adngin.cmd.startAuction(['leaderboard-3', 'halfpage-1', 'mpu-1'])
      })
    }
  }

  handleViewMore () {
    const initialArticles = Math.max(this.index, 0)
    const alreadyFetchedArticles = (Math.max(this.page, 0) * this.pageSize)
    this.props.searchArticlesMore(this.params.q, initialArticles + alreadyFetchedArticles, this.pageSize)
    this.page++
  }

  render () {
    if (this.props.isEmpty || this.props.hasError) {
      return (
        <div className='wrapper no-results'>
          <div id='adngin-leaderboard-1-0' className='advert-leaderboard-1' />
          <img src={NoResults} alt='No search results' />
          <h1>Nothing to see here</h1>
          <div>
            <p>Your search for <strong>{this.props.query}</strong> returned no results.</p>
          </div>
          <div className='no-results-search'>
            <form action='/search' method='get'>
              <input type='search' placeholder='Search House & Garden' name='q' id='q' />
              <button><Search /></button>
            </form>
          </div>
          <div id='adngin-leaderboard-3-0' className='advert-leaderboard-3' />
        </div>
      )
    }
    if (this.props.articles && this.props.articles.length && this.props.hasFetched) {
      const articles = this.props.articles.map(article => new IonArticle(article))
      return (
        <div className='wrapper'>
          <div id='adngin-leaderboard-1-0' className='advert-leaderboard-1' />
          <h1>Search Results for <em>{this.props.query}</em></h1>
          <div className='wrapper article-listing'>
            <div className='article-list' itemType='http://schema.org/ItemList'>
              {articles.map((article, index) => {
                return (
                  <Fragment key={article.contentKey}>
                    {index <= 2 && (
                      <ArticleCard article={article} noImage={noImage} />
                    )}
                    {index > 2 && (
                      <ArticleCard article={article} noImage={noImage} />
                    )}
                    {(index === 2) && (
                      <Mobile>
                        <MagazineCover />
                      </Mobile>
                    )}
                  </Fragment>
                )
              })}
              {this.props.hasMore && (
                <div id='buttonContainer' className='more-from-btn noScript'>
                  <>
                    <PulseLoader color='#B10015' loading={this.props.isLoadingMore} margin='10px' />
                    <button id='viewMoreButton' onClick={this.handleViewMore}> View More</button>
                  </>
                  <Helmet>
                    <link rel='canonical' itemprop='url' href={process.env.RAZZLE_SITE_URL + this.props.location.pathname + '?query=' + this.props.query} />
                  </Helmet>
                </div>
              )}
              {this.props.children}
            </div>
            <Sidebar section={this.props.section}>
              <DesktopTablet>
                <MagazineCover />
              </DesktopTablet>
            </Sidebar>
          </div>
          <div id='adngin-leaderboard-3-0' className='advert-leaderboard-3' />
        </div>
      )
    } else {
      return (
        <Segment>
          <Dimmer active>
            <Loader indeterminate size='large'>Loading...</Loader>
          </Dimmer>
        </Segment>
      )
    }
  }
}

SearchPage.propTypes = {
  searchArticles: PropTypes.func.isRequired,
  query: PropTypes.string
}

SearchPage.defaultProps = {
  articles: {}
}

const mapStateToProps = (state) => (state.search)
const mapDispatchToProps = (dispatch) => bindActionCreators({ searchArticles, searchArticlesMore }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage)
