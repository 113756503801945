import React from 'react'
import { Link } from 'react-router-dom'
import { ImageOrNoImage } from 'ion-image'

const ArticleCard = ({ article, noImage, lazy }) => (
  <article key={article.contentKey} aria-label='article'>
    <Link to={'/' + article.getCanonicalUri()}>
      <div className='article-list-img'>
        <ImageOrNoImage shape='square' width={400} image={article.image} alt={article.headline} noImage={noImage} />
      </div>
      <h4 role='heading'>{article.headline}
      </h4>
      {(article.sponsoredIndicator === 'SPONSORED' || article.sponsoredIndicator === 'SPONSORED_EXPIRED') &&
        <small className='sponsored'>Sponsored</small>}
      {(article.sponsoredIndicator === 'NATIVE' || article.sponsoredIndicator === 'NATIVE_EXPIRED') &&
        <small className='native'>Partnered</small>}
      <p>{article.abstract}</p>
    </Link>
  </article>
)

export default ArticleCard
