import React, { useState, useEffect } from 'react'
import StyledLink from '../components/StyledLink'
import { db } from '../db'
import { getCookie } from './ui/utils'

import bellIcon from '../images/brands/icons/notifications-ico.svg'

const NotificationsBell = (props) => {
  const [UnreadCount, setUnreadCount] = useState(0)
  useEffect(() => {
    const NotificationsLastViewed = getCookie('NotificationsLastViewed')
    db.messages.toArray().then(articles =>
      setUnreadCount((articles.filter(article =>
        Date.parse(article.timestamp) > Date.parse(NotificationsLastViewed))).length
      )
    )
  }, [])

  return (
    <StyledLink to='/my-notifications' mr={10} title='View recent push notifications.' className='my-notifications'>
      <div>
        <img src={bellIcon} alt={'View ' + UnreadCount + ' recent articles pushed to you.'} />
        {UnreadCount > 0 &&
          <span>
            <span>{UnreadCount}</span>
          </span>}
      </div>
      <div>View my notifications</div>
    </StyledLink>
  )
}

export default NotificationsBell
