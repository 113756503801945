import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { Prefetch } from '../../../store/app'
import { Articles } from 'ion-article-cmp'
import HomePageMeta from '../../components/HomePageMeta'
import WingBanners from '../../components/WingBanners'
import NoImage from '../../static/no-image.png'
import HomePageTemplate from '../../templates/Articles/HomePageHighlight'
import SiteSidebar from '../../components/Sidebar'
import logo from '../../static/general/house-and-garden.svg'
import { MobileElse, DesktopTablet } from 'ion-media'
import MagazineCover from '../../components/MagazineCover'

export class HomePage extends Component {
  constructor (props) {
    super(props)
    // Don't call this.setState() here!
    this.state = { isEmpty: true, is600: false, hasMounted: false }
  }

  componentDidMount () {
    // Don't prefetch at this stage
    // this.props.Prefetch()
    this.setState({ hasMounted: true })
    if (typeof window !== 'undefined' && window.adngin) {
      window.adngin.queue.push(function () {
        // trigger a new auction
        window.adngin.cmd.startAuction(['leaderboard-2', 'leaderboard-3', 'wingbanner-left', 'wingbanner-right', 'halfpage-1', 'mpu-1', 'instream'])
      })
    }
  }

  render () {
    return (
      <>
        <WingBanners useSmall={this.props.useSmall} isConnected={this.props.isConnected} location={this.props.location} onSlotRenderEndedLeft={this.props.onSlotRenderEndedLeft} onSlotRenderEndedRight={this.props.onSlotRenderEndedRight} />
        <Helmet title={process.env.RAZZLE_SITE_TITLE}>
          <meta property='og:url' content={process.env.RAZZLE_SITE_URL} />
          <meta name='description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
          <meta itemProp='description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
          <meta property='og:description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
          <meta name='twitter:site' content={process.env.RAZZLE_SITE_TWITTER} />
          <meta name='twitter:creator' content={process.env.RAZZLE_SITE_TWITTER} />
          <meta name='twitter:description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
          <meta name='twitter:title' content={process.env.RAZZLE_SITE_TITLE} />
          <meta name='twitter:card' content='summary_large_image' />
          <meta name='twitter:image:src' content={logo} />
        </Helmet>
        <HomePageMeta {...this.props} />
        <span>
          <div id='adngin-leaderboard-1-0' className='advert-leaderboard-1' />
        </span>
        <div className='wrapper article-listing' ref={this.setStickyRef}>
          <Articles {...this.props} component={HomePageTemplate} section='all-content-feed' count={18} pageSize={18} noImage={NoImage} withMore />
          <SiteSidebar {...this.props} section='homepage' sticky={this.state.sticky}>
            <div>
              {this.state.hasMounted &&
                <DesktopTablet>
                  <MagazineCover />
                </DesktopTablet>}
            </div>
          </SiteSidebar>
        </div>
        {this.state.hasMounted &&
          <MobileElse>
            <div id='adngin-leaderboard-3-0' className='advert-leaderboard-3' />
          </MobileElse>}
      </>
    )
  }
}

const mapStateToProps = (state) => ({ articles: state.articles })
const mapDispatchToProps = (dispatch) => bindActionCreators({ Prefetch }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
