import Dexie from 'dexie'

export const db = new Dexie('pushDatabase')
export const bm = new Dexie('bookmarksDatabase')

db.version(1).stores({
  messages: '++id, uuid'
})

bm.version(1).stores({
  messages: '++id, uuid, article'
})
