import React from 'react'
import { HagDesktop, defaultWidth } from 'ion-media'

const WingBanners = ({ useSmall }) => {
  return (
    <span>
      <HagDesktop values={{ deviceWidth: defaultWidth }}>
        <div className={'wing-banners horizontal-center' + (useSmall ? ' reduced-header' : '')}>
          <div id='adngin-wingbanner-left-0' className='advert-wingbanner-left' />
          <div id='adngin-wingbanner-right-0' className='advert-wingbanner-right' />
        </div>
      </HagDesktop>
    </span>
  )
}

export default WingBanners
