// import styled, { system } from '@xstyled/styled-components'
import { Link } from 'react-router-dom'

const StyledLink = ({ className, to, keyProp, replace, onClick, children }) => <Link key={keyProp} to={to} replace={replace} onClick={onClick} className={className}>{children}</Link>

// const StyledLink = styled(MyLink)`
//   ${system}
// `

export default StyledLink
