import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import qs from 'qs'
import { urlB64ToUint8Array } from './ServiceWorker'
import Popup from 'ion-popup'
import popupLogo from '../static/general/house-and-garden.svg'

const DefaultTemplate = ({ componentText, subscribeConcept }) => (
  <button className='follow-me-btn' onClick={() => subscribeConcept()}>{componentText}</button>
)

const FollowConcept = ({ topic, followText, unFollowText, template }) => {
  const Template = template || DefaultTemplate
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [helpPopupVisible, setHelpPopupVisible] = useState(false)
  const [subscription, setSubscription] = useState(false)
  const [registration, setRegistration] = useState(false)

  useEffect(() => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.getRegistration()
        .then(reg => {
          console.log('*** FollowConcept: registration', reg)
          setRegistration(reg)
        })
    }
  }, [])

  useEffect(() => {
    console.log('*** FollowConcept: registration', registration)
    if (registration && registration.pushManager) {
      registration.pushManager.getSubscription()
        .then(sub => {
          console.log('*** FollowConcept: subscription', sub)
          setSubscription(sub)
        })
    }
  }, [registration])

  useEffect(() => {
    console.log('FollowConcept setIsSubscribed', topic.name, subscription, topic.topic)
    async function setSubscribed () {
      const isSubscribed = await isSubscribedToConcept(topic.topic, subscription)
      setIsSubscribed(isSubscribed)
    }
    setSubscribed()
  }, [subscription, topic.topic])

  function isSubscribedToConcept (uuid, subscription) {
    if (!subscription) {
      console.log('*** isSubscribedToConcept: User is not subscribed to any push notifications')
      return Promise.resolve(false)
    }
    const data = qs.stringify({
      titleKey: process.env.RAZZLE_TITLE_KEY,
      endpoint: (subscription && subscription.endpoint) || ''
    })
    return axios.post(process.env.RAZZLE_PUSH_SERVICE + '/topics', data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(res => {
        const subscribed = res.data.filter(topic => topic.topic === uuid)
        console.log('*** subscriptions response', res.status, res.data, 'subscribed:', (subscribed.length > 0))
        return (subscribed.length > 0)
      })
      .catch(error => {
        console.log('*** isSubscribedToConcept error:', error.message)
        return false
      })
  }

  function subscribeConcept (uuid, subscription) {
    const data = {
      topic: uuid,
      titleKey: process.env.RAZZLE_TITLE_KEY,
      subscription: JSON.stringify(subscription)
    }
    console.log('*** FollowConcept:', 'Subscribing', data)
    console.log('Calling', isSubscribed ? 'unsubscribe' : 'subscribe', topic.name)
    axios.post(process.env.RAZZLE_PUSH_SERVICE + (isSubscribed ? '/unsubscribe' : '/subscribe'), qs.stringify(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(res => setIsSubscribed(!isSubscribed))
      .catch(error => {
        console.log(isSubscribed ? 'Unsubscribe' : 'Subscribe', 'Error:', error.message)
        return false
      })
  }

  function registerAndSubscribeConcept (uuid) {
    console.log('*** FollowConcept:', 'Registering and subscribing')
    registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlB64ToUint8Array(process.env.RAZZLE_SERVERKEY)
    })
      .then(function (subscription) {
        console.log('User IS now subscribed.')
        setSubscription(subscription)
        return subscribeConcept(uuid, subscription)
      })
      .catch(function (err) {
        console.log('Failed to subscribe the user: ', err)
        setHelpPopupVisible(true)
        setSubscription(false)
      })
  }

  const canPush = !!(process.env.RAZZLE_ENABLE_PUSHNOTIFICATIONS && typeof window !== 'undefined' && typeof window.navigator !== 'undefined' && subscription)
  const isFollowing = canPush && isSubscribed

  if (canPush) {
    return <Template componentText={isFollowing ? unFollowText : followText} subscribeConcept={() => subscribeConcept(topic.topic, subscription)} />
  } else {
    if (registration && registration.pushManager) {
      return (
        <>
          {helpPopupVisible &&
            <Popup visible>
              <div className='push-notification'>
                <div>
                  <img src={popupLogo} alt={process.env.RAZZLE_SITE_NAME} />
                </div>
                <div color='grey'>
                  <p>Small problem..</p>
                  <p>You have chosen to block notifications.</p>
                  <p>To follow stories requires you to enable notifications in your browser settings.</p>
                  <div display='flex' justifycontent='flex-end'>
                    <Link onClick={() => setHelpPopupVisible(false)} to='/help'>Help</Link>
                    <button onClick={() => setHelpPopupVisible(false)}>OK</button>
                  </div>
                </div>
              </div>
            </Popup>}
          <Template componentText={followText} subscribeConcept={() => registerAndSubscribeConcept(topic.topic)} />
        </>
      )
    } else {
      return null
    }
  }
}

export default FollowConcept
