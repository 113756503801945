import React from 'react'
import { Link } from 'react-router-dom'
import { ImageOrNoImage } from 'ion-image'
import NoImage from '../static/no-image.png'

const SidebarArticleCard = ({ article, noImage }) => (
  <article>
    <Link to={'/' + article.getCanonicalUri()}>
      <ImageOrNoImage image={article.getImageObject()} width={120} alt={article.headline} shape='square' noImage={NoImage} />
    </Link>
    <p>
      <Link to={'/' + article.getSectionId()}>
        {article.getGrandSectionId()}
      </Link>
    </p>
    <p>
      <Link to={'/' + article.getCanonicalUri()}>
        {article.headline}
      </Link>
      {(article.sponsoredIndicator === 'SPONSORED' || article.sponsoredIndicator === 'SPONSORED_EXPIRED') &&
        <small className='sponsored'>Sponsored</small>}
      {(article.sponsoredIndicator === 'NATIVE' || article.sponsoredIndicator === 'NATIVE_EXPIRED') &&
        <small className='native'>Partnered</small>}
    </p>
  </article>
)

export default SidebarArticleCard
