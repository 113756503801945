import React from 'react'
import { ImageOrNoImage } from 'ion-image'
import FollowConcept from '../components/FollowConcept'
import { Link } from 'react-router-dom'

const AuthorBio = (props) => {
  return (
    <>
      <div className='author-bio'>
        <ImageOrNoImage shape='square' width={450} image={{ url: props.imageUrl }} alt={props.name} noImage={props.noImage} />
        <h4><Link to={'/' + props.slug}>{props.name}</Link></h4>
        {props.description && <p>{props.description}</p>}
        <FollowConcept
          topic={{ topic: props.uuid, type: 'author', name: props.name }}
          followText='Follow My Stories'
          unFollowText='Unfollow My Stories'
        />
      </div>
    </>
  )
}

export default AuthorBio
